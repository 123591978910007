import { Fragment, useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { Maybe, SanityImage } from '../../graphql-types';
import ImageWithCropAndHotspot from './ImageWithCropAndHotspot';

interface Props {
    images: Maybe<Array<Maybe<SanityImage>>>;
}

export default function ProductImageSlider({ images }: Props) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    if (!images) return null;

    const renderImage = image => {
        if (!image.asset.gatsbyImageData) return null;
        const { asset, crop, hotspot } = image;

        return (
            <Box
                height="60vh"
                minH={[300, 450, 600]}
                rounded="lg"
                overflow="hidden"
                willChange="transform"
            >
                <ImageWithCropAndHotspot
                    image={asset.gatsbyImageData}
                    crop={crop}
                    hotspot={hotspot}
                    alt={`Product image number ${currentImageIndex + 1}`}
                    style={{
                        objectFit: 'cover',
                        height: '100%',
                    }}
                />
            </Box>
        );
    };

    const renderThumb = (image, index: number) => {
        if (!image.asset.gatsbyImageData) return null;
        const { asset } = image;

        let activeStyles = {};
        if (index === currentImageIndex) {
            activeStyles = {
                boxShadow: 'outline',
                color: 'primary.600',
            };
        }
        return (
            <Box
                onClick={() => setCurrentImageIndex(index)}
                onMouseEnter={() => setCurrentImageIndex(index)}
                sx={{
                    rounded: 'lg',
                    overflow: 'hidden',
                    willChange: 'transform',
                    cursor: 'pointer',
                    ...activeStyles,
                }}
            >
                <ImageWithCropAndHotspot
                    image={asset.gatsbyImageData}
                    alt={`Product thumbnail number ${index + 1}`}
                    style={{
                        objectFit: 'cover',
                        height: '100px',
                        width: '100%',
                    }}
                />
            </Box>
        );
    };

    const renderThumbs = () => {
        if (images.length === 1) return null;
        return (
            <SimpleGrid columns={4} spacing={4} sx={{ paddingTop: 4 }}>
                {images.map((image, index) => (
                    <Fragment key={index}>{renderThumb(image, index)}</Fragment>
                ))}
            </SimpleGrid>
        );
    };
    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100vw',
            }}
        >
            {renderImage(images[currentImageIndex])}
            {renderThumbs()}
        </Box>
    );
}
