import { graphql } from 'gatsby';
import {
    Grid,
    GridItem,
    Heading,
    Button,
    Container,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    ListItem,
    Text,
    ListIcon,
    List,
    Divider,
} from '@chakra-ui/react';
import { GiWaterDrop, GiTowel, GiLiquidSoap } from 'react-icons/gi';
import ProductImageSlider from '../components/ProductImageSlider';
import useLocale from '../lib/useLocale';
import BlockContent from '../components/BlockContent';
import SEO from '../components/SEO';
import { SanityProduct } from '../../graphql-types';
import formatMoney from '../lib/formatMoney';
import ProductCard from '../components/ProductCard';
import ProductBadge from '../components/ProductBadge';
import getProductBySlug from '../lib/getProductBySlug';

interface Props {
    data: {
        product: SanityProduct;
        similarProducts: {
            nodes: Array<SanityProduct>;
        };
    };
    serverData: {
        isAvailable: boolean;
    };
}

export default function ProductTemplate({
    data: { product, similarProducts },
    serverData: { isAvailable },
}: Props) {
    const { translate, format } = useLocale();

    if (!product.title) return null;

    const description = product.description
        ? translate(product.description)
        : '';

    const price = (product.price || 0) / 100;

    const renderAccordionItem = (title: string, content: any) => (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ color: 'primary.600' }}>
                    <Box flex="1" textAlign="left" py={4}>
                        {title}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{content}</AccordionPanel>
        </AccordionItem>
    );
    return (
        <Container maxW="container.xl" py="12">
            <SEO
                title={translate(product.title)}
                description={description}
                image={product?.images?.[0]?.asset?.gatsbyImageData}
            />
            <Grid
                templateColumns={['1fr', 'repeat(2, 1fr)']}
                gap={[10, 16]}
                justifyItems="center"
            >
                <GridItem w="100%" minH={0} minW={0} h="100%">
                    <ProductImageSlider images={product?.images || []} />
                </GridItem>
                <GridItem w="100%">
                    <Box>
                        <Heading as="h1" size="2xl" mb="4" fontWeight="bold">
                            {translate(product.title)}
                        </Heading>
                        {!!product.state && (
                            <ProductBadge state={product.state} />
                        )}
                        <Heading as="h2" size="lg">
                            {formatMoney(product.price || 0)}
                        </Heading>
                        {!!description && (
                            <Box color="gray.600" mt={6}>
                                <Text>{description}</Text>
                            </Box>
                        )}
                        {isAvailable && (
                            <Button
                                mt={6}
                                className="snipcart-add-item"
                                data-item-id={product._id}
                                data-item-price={price}
                                data-item-url={`/productos/${product?.slug?.current}`}
                                data-item-description={description}
                                data-item-image={
                                    product?.images?.[0]?.asset?.gatsbyImageData
                                        ?.images?.fallback?.src
                                }
                                // data-item-name={product.title.es}
                                data-item-name={translate(product.title)}
                                // data-item-max-quantity={1}
                                size="lg"
                                colorScheme="primary"
                                w="100%"
                            >
                                {format('addToCart')}
                            </Button>
                        )}
                    </Box>
                    <Accordion mt={12} defaultIndex={[0]} allowMultiple>
                        {!!product.features &&
                            renderAccordionItem(
                                'Características',
                                <BlockContent data={product.features} />
                            )}

                        {renderAccordionItem(
                            'Cuidados',
                            <>
                                <Text>
                                    Manipule con cuidado y ten en cuenta que,
                                    aunque esté sellado, el concreto es
                                    naturalmente poroso y se pueden producir
                                    manchas.
                                </Text>
                                <Text>
                                    Nuestras piezas son fuertes y duraderas,
                                    pero deben tratarse como cerámica, ya que
                                    pueden romperse o astillarse si se caen o
                                    golpean.
                                </Text>
                                <List mt={3}>
                                    <ListItem>
                                        <ListIcon as={GiWaterDrop} />
                                        Utilice abuntande agua
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={GiTowel} />
                                        Utilice un paño o esponja mojada
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={GiLiquidSoap} />
                                        Utilice un jabón de manos, no cloro ni
                                        desinfectantes
                                    </ListItem>
                                </List>
                            </>
                        )}
                        {renderAccordionItem(
                            'Envíos',
                            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.`
                        )}
                        {renderAccordionItem(
                            'Devoluciones',
                            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.`
                        )}
                    </Accordion>
                </GridItem>
            </Grid>
            {!!similarProducts.nodes.length && (
                <Box pt={20}>
                    <Divider />
                    <Heading size="lg" mb="4" mt={10}>
                        También te puede interesar
                    </Heading>
                    <Grid
                        mt="12"
                        templateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
                        gap={6}
                    >
                        {similarProducts.nodes.map(item => (
                            <ProductCard key={item.id} product={item} />
                        ))}
                    </Grid>
                </Box>
            )}
        </Container>
    );
}

export const query = graphql`
    query ($slug: String!, $category: String!, $language: String!) {
        product: sanityProduct(slug: { current: { eq: $slug } }) {
            ...SanityProductFragment
        }
        similarProducts: allSanityProduct(
            filter: {
                slug: { current: { ne: $slug } }
                available: { eq: true }
                categories: { elemMatch: { id: { eq: $category } } }
            }
            limit: 3
        ) {
            nodes {
                ...SanityProductFragment
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;

// In case someone bought the product and the page is regenerating
// this will get the available status in real time
export async function getServerData({ pageContext }) {
    const { slug } = pageContext;
    let product: SanityProduct | null = null;
    if (slug) {
        product = await getProductBySlug(slug);
    }
    return {
        props: {
            isAvailable: product?.available || false,
        },
    };
}
